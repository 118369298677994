import prestashop from 'prestashop';
import $ from 'jquery';

var a = document,
	// o = console,
	o = { log: function() { return null; }},
	t; // undefined


/*
<script src="https://www.vivino.com/external/vivino-widgets.js" type="text/javascript" async></script>
 */
/**
 * This function appends scripts
 */
function i() {
	var e = a.getElementsByClassName("vivino-widget");
	o.log('Vivino: Init');
	o.log('Vivino: Element', e);
	if (0 < e.length) {
		o.log('Vivino: Exists');
		// var i = a.createElement("link");
		// i.rel = "stylesheet",
		// 	i.type = "text/css",
		// 	i.href = "https://www.vivino.com/external/vivino-widgets.css",
		// 	i.media = "all",
		// 	a.getElementsByTagName("head")[0].appendChild(i);
		o.log('Vivino: Elements count', e.length);
		for (var k = e.length - 1; 0 <= k; k--) {
			var n = e[k];
			o.log('Vivino: Element dataset', n.dataset);
			if (n.dataset) {
				switch (n.dataset.vivinoWidgetType) {
					case "user":
						r(n);
						o.log('Vivino: User widget type choosed');
						break;
					case "wine":
						s(n);
						o.log('Vivino: Wine widget type choosed');
				}
			}
			else {
				o && o.error("Vivino widget error: Browser not supported.")
			}
		}
	}
}
function n(e, i, t) {
	try {
		var n = new XMLHttpRequest;
		n.open("GET", e + i, !0),
			n.onload = function() {
				if (200 === n.status) {
					var e = JSON.parse(n.response);
					if (!0 === e.success) {
						successLoadPreCallback(t);
						if (e.content) {
							t.innerHTML = e.content;
						}
						successLoadCallback(t);
					} else if (e.error && o) {
						o.error("Vivino widget error: ", e.error);
					}
				}
			}
			,
			n.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8"),
			n.send()
	} catch (a) {
		o && o.log("Vivino widget error: Browser not supported", a)
	}
}
function r(e) {
	if (e.dataset.vivinoUserId != t) {
		var i = "?user_id=" + e.dataset.vivinoUserId;
		e.dataset.vivinoWidgetLayout && (i += "&layout=" + e.dataset.vivinoWidgetLayout),
		e.dataset.vivinoWidgetIncludeReview && (i += "&include_review=" + e.dataset.vivinoWidgetIncludeReview),
			n("https://www.vivino.com/external/widgets/user", i, e)
	} else
		o && o.error("Vivino widget error: Missing user id on", e)
}
function s(e) {
	if (e.dataset.vivinoVintageId != t) {
		var i = "?vintage_id=" + e.dataset.vivinoVintageId;
		e.dataset.vivinoWidgetLayout && (i += "&layout=" + e.dataset.vivinoWidgetLayout),
		e.dataset.vivinoWidgetReadReviews && (i += "&read_review=" + e.dataset.vivinoWidgetReadReviews),
		e.dataset.vivinoWidgetFeaturedDescription && (i += "&featured_review=" + e.dataset.vivinoWidgetFeaturedDescription),
		e.dataset.vivinoUserId && (i += "&user_id=" + e.dataset.vivinoUserId),
		e.dataset.vivinoWidgetLanguage && (i += "&language=" + e.dataset.vivinoWidgetLanguage),
			n("https://www.vivino.com/external/widgets/vintage", i, e)
	} else
		o && o.error("Vivino widget error: Missing wine id on", e)
}

function reinitialize() {
	o.log('Reinitalization of vivino widget');
	var e = a.getElementsByClassName("vivino-widget");
	for (var t = e.length - 1; 0 <= t; t--) {
		var n = e[t];
		if (n.dataset)
			switch (n.dataset.vivinoWidgetType) {
				case "user":
					r(n);
					break;
				case "wine":
					s(n)
			}
		else
			o && o.error("Vivino widget error: Browser not supported.")
	}
}

function successLoadPreCallback(element) {
	o.log('Vivino: Pre load success callback', element);
	let $element = $(element);
	$element.hide();
}

function successLoadCallback(element) {
	o.log('Vivino: Load success callback', element);
	let $element = $(element);
	let rate = parseFloat($element.find('.vivino-widget-rating-avg').html());
	if (isNaN(rate)) {
		rate = 0;
	}
	let data = {};
	data.rate = rate;
	data.url = $element.find('a').attr('href');
	o.log('Vivino: Loaded wine rate', rate);

	renderWidget(data, $element);

	if (rate > 3.5) {
		$element.show();
	}
}

/**
 * @param {object} data
 * @param {jQuery} target
 */
function renderWidget(data, target) {
	target.html(`
		<a class="d-flex align-items-center" href="${data.url}">
			<span class="nvc-wnet-wineratings__label">
				<i class="nvc-wnet-wineratings__icon mr-2"></i>
			</span>
			<span class="nvc-wnet-wineratings__rate base-text--18">${data.rate.toFixed(1).replace('.', ',')}</span>
		</a>
	`);
}

$(document).ready(function() {
	prestashop.on('updatedProduct', () => {
		reinitialize();
	});
	i();
});