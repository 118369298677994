import View from './View';

const templatesKDWPoints = {
	/**
	 * @param {Array<string>} errors
	 *
	 * @returns {string}
	 */
	errors(errors) {
		if (errors.length) {
			let all = errors.reduce((prev, currentVal) => {
				return prev + `<li>${currentVal}</li>`
			}, '');
			return this.fetch('error', [ all ]);
		}
		return ''
	},
	/**
	 * @param message
	 *
	 * @returns {string}
	 */
	error(message) {
		if (!message) return '';
		return `<div class="alert alert-danger my-3">${message}</div>`;
	},

	/**
	 * @param message
	 *
	 * @returns {string}
	 */
	success(message) {
		if (!message) return '';
		return `<div class="alert alert-success my-3">${message}</div>`;
	},
};


export class KDWPoints extends View {
	constructor() {
		super({
			scope: '.js-my-account-points-view',
			accessors: {
				krs() {
					return this.find('.js-krs-input').val();
				},
				donationEnabled() {
					let e = this.find('.js-toggle-donation:checked').val();
					return typeof e !== 'undefined' && e !== 0 && e !== "0";
				},
				errors() {
					return this.data.errors;
				},
				success() {
					return this.data.success;
				}
			},
			data: {
				success: false,
				errors: false,
			},
			templates: templatesKDWPoints,
			globalSelectors: {
				goalPopup: '#oppPopup',
				oppPopupForm: '#oppPopupForm',
				oppPopupMessages: '#oppPopupMessages',
			}
		});
	}
	getGoalSelectionForm() {
		return this.find('.js-toggle-donation-form');
	}
	getGoalPopup() {
		return $(this.globalSelectors.goalPopup);
	}
	getPopupFormElement() {
		return $(this.globalSelectors.oppPopupForm);
	}
	getPopupMessagesElement() {
		return $(this.globalSelectors.oppPopupMessages);
	}
	render() {
		let d = this.data;
		if (d.donationEnabled) {
			this.find('.js-donation-opp-block').show();
			this.find('.js-rabatback-block').hide();
		} else {
			this.find('.js-donation-opp-block').hide();
			this.find('.js-rabatback-block').show();
		}

		let messageContent = '';
		if (d.success) {
			messageContent = this.fetch('success', [ this.l('success-info') ]);
		} else {
			if (d.errors) {
				if (d.errors instanceof Array) {
					messageContent = this.fetch('errors', [ d.errors ]);
				} else if (typeof d.errors === 'string') {
					messageContent = this.fetch('error', [ d.errors ]);
				} else {
					messageContent = this.fetch('error', [ this.l('error-occurred') ]);
				}
			}
		}

		this.getPopupMessagesElement().html(messageContent);
	}
}

export class KDWPointsBuilder {
	static build() {
		const view = new KDWPoints();

		$(document).on('change', view.scopedSelector('.js-toggle-donation'), function (e) {
			view.refresh();
			var form = view.getGoalSelectionForm();

			if(view.data.donationEnabled && !view.data.krs) {
				view.getGoalPopup().modal('show');
			} else {
				$.ajax({
					method: 'POST',
					url: form.attr('action'),
					dataType: 'json',
					data: form.serialize(),
					success: function (response) {
						if(!response.success) {
							alert(response.error);
						}
						view.refresh();
					}
				});
			}
		});

		$(document).on('submit', view.globalSelectors.oppPopupForm, function (e) {
			e.preventDefault();
			let form = view.getPopupFormElement();

			$.ajax({
				method: 'POST',
				url: form.attr('action'),
				dataType: 'json',
				data: form.serialize(),
				success: function (response) {
					view.mutate('success', response.success);
					view.mutate('errors', response.errors ? response.errors : []);
					if (response.success) {
						setTimeout(() => {
							window.location.reload();
						}, 2500);
					}
				}
			});
		});

		return view;
	}
}
