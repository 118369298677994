import $ from 'jquery';

export default class Tooltip {
  init() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  refresh() {
    const all = $('[data-toggle="tooltip"]');
    all.tooltip('dispose');
    all.tooltip();
  }
}
