import $ from 'jquery';

/**
 *
 * @returns {}
 */
function buildDictionary() {
	let dict = {};
	$(this.scope)
		.each(function() {
			$.each(this.attributes, function() {
				// this.attributes is not a plain object, but an array
				// of attribute nodes, which contain both the name and value
				if(!this.specified || typeof this.name !== 'string') {
					return true
				}
				if (!this.name.match(/^data\-i18n\-/)) {
					return true;
				}
				dict[this.name.replace('data-i18n-', '')] = this.value;
			});
		});
	return {};
}

function searchInDictionaryAgain(translationKey) {
	return $(this.scope).attr(`data-i18n-${translationKey}`);
}

export default class View {
	refresh() {
		for(let k in this.accessors) {
			if (!this.accessors.hasOwnProperty(k)) continue;
			this.data[k] = this.accessors[k].call(this);
		}
		this.render();
	}

	find(selector) {
		return $(selector, this.scope);
	}

	scopedSelector(selector) {
		return this.scope + ' ' + selector;
	}

	/**
	 * @param {object} data
	 * @param {string} data.scope - html selector
	 * @param {object} data.accessors
	 */
	constructor(data) {
		this.scope = data.scope ? data.scope : 'body';
		this.accessors = typeof data.accessors === 'object' ? data.accessors : {};
		this.data = typeof data.data === 'object' ? data.data : {};
		this.globalSelectors = typeof data.globalSelectors === 'object' ? data.globalSelectors : {};
		this.templates = typeof data.templates === 'object' ? data.templates : {};
		this.i18nDict = buildDictionary.apply(this);
		this.refresh();
	}

	/**
	 * @param {string} templateName
	 * @param {Array} params
	 *
	 * @returns {string}
	 */
	fetch(templateName, params) {
		if (typeof this.templates[templateName] !== 'function') {
			return ''
		}
		let paramsLoc = params ? params : [];
		return this.templates[templateName].apply(this, paramsLoc);
	}

	/**
	 * @param {string} dataField
	 * @param {any} value
	 */
	mutate(dataField, value) {
		this.data[dataField] = value;
		this.refresh();
	}

	/**
	 * @param {string} translationKey
	 * @param {string} fallback
	 * @returns {string}
	 */
	l(translationKey, fallback) {
		let fallbackLoc = fallback ? fallback : `${translationKey} [untranslated]`;
		if (!translationKey) {
			return fallbackLoc;
		}
		if (typeof this.i18nDict[translationKey] !== 'undefined') {
			return this.i18nDict[translationKey];
		}
		let dynamicallyFetchedFromElement = searchInDictionaryAgain.call(this, translationKey);
		if (dynamicallyFetchedFromElement) {
			return dynamicallyFetchedFromElement;
		}
		return fallbackLoc;
	}

	render() {

	}
}