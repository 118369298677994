import SwiperSlider from './SwiperSlider';

export default class StoreGallery {
  init() {
    this.initSliders();
  }

  initSliders() {
    this.swiperSlider = new SwiperSlider({
      loop: false,
    });

    this.thumbs = this.swiperSlider.init('#nvc-wnet-store-thumbs', {
      slidesPerView: 4,
      slideToClickedSlide: true,
      spaceBetween: 19
    });

    this.covers = this.swiperSlider.init('#nvc-wnet-store-gallery');

    if(this.covers.length && this.thumbs.length) {
      this.covers[0].on('slideChange', () => {
        this.thumbs[0].slideTo(this.covers[0].activeIndex, 200);

        $(this.thumbs[0].wrapperEl).find('img').removeClass('selected');
        $(this.thumbs[0].slides[this.covers[0].activeIndex]).find('img').addClass('selected');
      });

      this.thumbs[0].on('click', (el) => {
        this.covers[0].slideTo(this.thumbs[0].clickedIndex, 200);
      });

      this.thumbs[0].on('slideChange', () => {
        this.covers[0].slideTo(this.thumbs[0].activeIndex, 200);
      });
    }
  }
}
