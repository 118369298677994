$(document).ready(function() {
  var button = $('button[name="confirmDeliveryOption"]');

  button.on('click', function(e) {
    var id_carrier = parseInt($('#id_carrier_authorization').val());
    var id_carrier_selected = parseInt($('.js-input-delivery:checked').val());

    var input = $('#nvc_wnet_authorizationcarrier-identification');

    if(id_carrier === id_carrier_selected) {
      e.preventDefault();

      $.ajax({
        type: 'POST',
        url: input.data('action'),
        async: true,
        dataType: 'json',
        data: input.serialize(),
        success: function(response) {
          if (response.success) {
            button.off('click').click();
            input[0].setCustomValidity('');
          } else {
            input.closest('form').addClass("was-validated");
            input.closest('.form-group').find('.js-invalid-feedback-browser').html(response.error);
            input[0].setCustomValidity(response.error);
          }
        }
      });

      input.on('keyup', function () {
        input[0].setCustomValidity('');
      });
    } else {
      input[0].setCustomValidity('');
    }
  });
});

