/* ------------------------------------------------------------- */
/* WEBSOCKET SERVICE */
/* ------------------------------------------------------------- */
/* eslint-disable */
/*
* Example mapping:
    {
      "name": "theme:classic-rocket",
      "socket": "ws://127.0.0.1:8089",
      "mainCSS": "/themes/classic-rocket/views/css/theme.css"
    }
*/
import mappings from '../build/dev-map';

const ls = {
  /**
   * Retrive an object from local storage.
   * @param  {string} key
   * @return mixed
   */
  get: function get(key) {
    var item = localStorage.getItem(key);

    if ( ! item ) return;

    if ( item[0] === '{' || item[0] === '[' ) return JSON.parse(item);

    return item;
  },
  /**
   * Save some value to local storage.
   * @param {string} key
   * @param value
   */
  set: function set (key, value) {
    if ( value === undefined ) $.error("Can't store undefinded value");

    if ( typeof(value) === 'object' || typeof(value) === 'array' ) {
      value = JSON.stringify(value);
    }

    if ( typeof(value) !== 'string' ) $.error("Can't store unrecognized format value");

    localStorage.setItem(key, value);
  }
};

const dev = {
  mappings: mappings,
  reloadStylesheet: function(pattern) {
    var p = new RegExp(pattern.replace('.', '\.'));
    var queryString = `?reload=${new Date().getTime()}`;
    $('link[rel="stylesheet"]').each(function () {
      if (this.href.match(p)) {
        this.href = this.href.replace(/\?.*|$/, queryString);
      }
    });
  },

  isCSS(key) {
    return typeof key === 'string' && key.match(/\.css|\.scss$/);
  },
  isJS(key) {
    return typeof key === 'string' && key.match(/\.js$/);
  },
  reloadPage() {
    window.location.reload();
  },

  optionalHashlist: function(list) {
    var storedHashlist = ls.get('wp-hashlist') || {};
    ls.set('wp-hashlist', list);
    for (var k in list) {
      if (!list.hasOwnProperty(k)) {
        continue;
      }
      var obj = list[k];
      if (this.isCSS(k)) {
        continue;
      }
      if (!this.isJS(k)) {
        continue;
      }
      if (typeof storedHashlist[k] === 'undefined') {
        this.reloadPage();
      }
      var localObj = storedHashlist[k];
      if (localObj.hash != obj.hash) {
        this.reloadPage();
      }
    }
  },
  socketInitializer: function(obj){
    var that = this;
    // if user is running mozilla then use it's built-in WebSocket
    // console.log('Initializing connection to build toolchain for: '+obj.name);

    window.WebSocket = window.WebSocket || window.MozWebSocket;

    var connection = null;

    try {
      connection = new WebSocket(obj.socketAddress+":"+obj.socketPort);
    } catch (e) {
      connection = {};
      console.warn(obj.name+' toolchain inactive.');
    }

    connection.onopen = function () {
      // connection is opened and ready to use
      console.log('Connection with "'+obj.name+'" toolchain successfully established. ');
    };

    connection.onerror = function (error) {
      // an error occurred when sending/receiving data
      // console.error(error);
    };

    connection.onmessage = function (message) {
      // try to decode json (I assume that each message
      // from server is json)
      try {
        var json = JSON.parse(message.data);
        if (typeof json.hashes === 'object') {
          that.optionalHashlist(json.hashes);
        }
        if (json.type == 'scss') {
          that.reloadStylesheet(obj.mainCSS);
        }
      } catch (e) {
        console.log('This doesn\'t look like a valid JSON: ', message.data);
      }
      // handle incoming message
    };
  },
  init: function () {
    var that = this;

    $(document).ready(function () {
      if (that.mappings instanceof Array) {
        that.mappings.forEach(function(obj){
          that.socketInitializer(obj);
        });
      }
    });
  },
};

export default dev;
