export default {
  refresh() {
    const cartFooter = $('#cart-footer');

    $.ajax({
        url: cartFooter.data('update-url'),
        dataType: 'json',
        success(response) {
          if( response.status === 'ok'){
            $('#cart-footer').replaceWith(response.html);
            prestashop.emit('cartFooterUpdated');
          }
        }
    })
  }
}
