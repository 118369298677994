import copy from 'copy-to-clipboard';
$(() => {
  $(document).on('click', '.copy', function(e){
    e.preventDefault();
    copy($(this).text());
  });

   $(document).on('click', '.voucher-copy', function(e){
    e.preventDefault();
    copy($(this).data('code'));
    $(this).tooltip('show');
     setTimeout(() => {
       $(this).tooltip('hide');
     }, 2000);
   });
});
