import SwiperSlider from './SwiperSlider';

export default class ProductGallery{
  init(){
    this.initSliders();
    this.initEvents();
  }

  initSliders(){
    this.swiperSlider = new SwiperSlider({
      loopedSlides: 3,
      loop: false,
    });

    this.thumbs = this.swiperSlider.init('.product-thumbs', {
      slidesPerView: 3,
      slideToClickedSlide: true,
      centeredSlides: true,
      direction: 'vertical',
      navigation: {
        nextEl: '.js-product-thumbs-next',
        prevEl: '.js-product-thumbs-prev',
      },
    });

    this.big = this.swiperSlider.init('.js-products-big', {
      spaceBetween: 0,
      zoom: true,
      preloadImages: false,
      lazy: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.js-products-big-next',
        prevEl: '.js-products-big-prev',
      },
    });

    this.covers = this.swiperSlider.init('.products-imagescover');

    if(this.covers.length && this.thumbs.length){
      this.covers[0].controller.control = this.thumbs[0];
      this.thumbs[0].controller.control = this.covers[0];
    }
  }

  addListenerToBigSlider() {
    const $productBig = $('.js-products-big');
    const $productBigOpenButton = $('.js-products-big-open-button');
    $(document).on('click', '.js-products-big-open-button', (e) => {
      e.preventDefault();
      $productBig.toggleClass('opened');
      $productBigOpenButton.toggleClass('opened');
    });
  }

  initEvents() {
    this.addListenerToBigSlider();
  }
}
