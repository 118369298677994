import SwiperSlider from './SwiperSlider';
import productList from './productList';

export default {
  init(container) {
    const swiperSlider = new SwiperSlider();
    swiperSlider.init(container, {
      navigation: {
        nextEl: '.js-featured-products-button-next',
        prevEl: '.js-featured-products-button-prev',
      },
      breakpoints: {
        1279: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        576: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    })

    productList.init('.js-featured-products-slider');
  }
}
