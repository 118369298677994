import SwiperSlider from 'theme/js/components/SwiperSlider';

(($) => {
  const swiperSlider = new SwiperSlider();
  const initSlider = (container) => {
    return swiperSlider.init(container, {
      navigation: {
        nextEl: item => $(item).closest('.products-slider-home__tab-pane').find('.js-featured-tabs-products-slider-next'),
        prevEl: item => $(item).closest('.products-slider-home__tab-pane').find('.js-featured-tabs-products-slider-prev'),
      },
      breakpoints: {
        1279: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        576: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      },
    });
  };

  initSlider('.js-featured-tabs-products-slider:visible');
  $(document).on('shown.bs.tab', '.products-slider-home a[data-toggle="tab"]', (e) => {
    let tabPane = $($(e.currentTarget).attr('href') + ' .js-featured-tabs-products-slider');
    initSlider(tabPane);
    swiperSlider.swipers.forEach(item => item.update());
  });
})(jQuery);
