export default {
  init(container = ''){
    $(container + ' .spinner__input').each((index, item) => {
      $(item).TouchSpin({
        buttondown_class: 'btn js-touchspin',
        buttonup_class: 'btn js-touchspin',
        min: parseInt($(item).attr('min'), 10),
        max: parseInt($(item).attr('max'), 10),
      });
    });
  }
}
